<template>

    <div class="w-full h-full module flex py-0 px-0">

        <div class="px-6 py-4 w-1/5 flex flex-col">

            <top-scores class="h-40" />

            <div class="mt-6 box-title mb-2">Last posts</div>
            <div class="flex-1 min-h-0">
                <last-posts view="personalDashboard" class="mt-1" />
            </div>

            <div class="h-10 rounded-full bg-body mt-2 cursor-pointer flex flex-row justify-center items-center" @click="goSmart()">
                <img class="h-6 text-white" src="/images/smarp.png">
            </div>

            <div class="mt-4">
                <button class="pill-aux block w-full"
                    @click="$router.push( { name: 'TipsAndDocs' })">
                    <i class="mdi mdi-file-document-outline"></i> TIPS & DOCS
                </button>
            </div>
            
        </div>

        <separator :direction="'vertical'" />

        <div class="w-4/5 h-full flex flex-col relative">
            <div class="px-6 py-4">
                <div>
                    <last-updates />
                </div>
            </div>
            <separator :direction="'horizontal'" />
            <div class="px-6 py-4 flex-1 min-h-0 flex flex-col">
                <div v-if="currentRoute !== 'TipsAndDocs'" class="mb-4 flex">
                    <template v-for="(route, index) in routes">
                        <router-link
                            v-if="route.name !== 'TipsAndDocs'"
                            :key="route.name"
                            :to="{ name: route.name }"
                            :class="(route.name === $route.name) ? 'pill-' + route.name : 'pill-gray hover:pill-' + route.name"
                            class="ml-4 first:ml-0 px-2 py-2 flex items-center rounded-xl">
                            <i class="text-xl" :class="'mdi mdi-'+ route.meta.icon"></i>&nbsp;
                            <p class="ml-2 text-lg font-regular leading-none">
                                {{ route.meta.catName }}
                            </p>
                        </router-link>
                    </template>
                </div>
                <div class="flex-1 min-h-0">
                    <transition name="fade" enter-active-class="animated fadeIn"
                        leave-active-class="animated fadeOut" mode="out-in">
                        <router-view  style="animation-duration: .3s"
                            class="h-full overflow-hidden"/>
                    </transition>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    const LastPosts = () => import('@/components/Desktop/PersonalDashboard/LastPosts');
    const SocialMedia = () => import('@/components/Desktop/PersonalDashboard/SocialMedia');
    const lastUpdates = () => import('@/components/Globals/PersonalDashboard/LastUpdates');
    const topScores = () => import("@/components/Desktop/PersonalDashboard/topScores");
    import { getNestedRoutes } from "@/router";

    export default {
        components: {
            LastPosts,
            SocialMedia,
            lastUpdates,
            topScores
        },
        data() {
            return {
                cats: {
                    lastUpdates: { name:'Last updates' }
                }
            }
        },
        methods:{
            goSmart(){
                window.open('https://ey.smarpshare.com/#/', '_blank').focus();
            }
        },
        computed: {
            currentRoute() { return this.$route.name },
            routes() { return getNestedRoutes('desktop.PersonalDashboard') }
        }
    }
</script>